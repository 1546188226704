import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from './Button/Button';
import style from './pushPanel.module.css';
import SearchInput from './SearchInput/SearchInput';

interface PushPanelProps {
  setIsSearching?: (isSearching: boolean) => void;
  onSearch?: (query: string) => void;
}

const PushPanel: React.FC<PushPanelProps> = ({ setIsSearching, onSearch }) => {
  const location = useLocation();
  const [query, setQuery] = useState<string>('');

  const handleSearchChange = (query: string) => {
    setQuery(query);
    onSearch?.(query);
  };

  const handleClearSearch = () => {
    setQuery('');
    setIsSearching?.(false);
  };

  return (
    <div className={style.panel_wrap}>
      <div className={style.wrapState}>
        <Button content={'Активні'} />
        <Button content={'Завершені'} />
      </div>
      <SearchInput
        query={query}
        onChange={handleSearchChange}
        onClear={handleClearSearch}
      />
      <div className={style.container_button}>
        <Button content={'Додати'} />
      </div>
    </div>
  );
};

export default PushPanel;
