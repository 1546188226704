import { createReducer } from '@reduxjs/toolkit';
import { Translator } from '../../types';
import {
  appendTranslatorsSuccess,
  deleteTranslator,
  loadTranslatorsFailure,
  loadTranslatorsRequest,
  loadTranslatorsSuccess,
  searchTranslatorsFailure,
  searchTranslatorsRequest,
  searchTranslatorsSuccess,
  setFetchLimit,
  updateTranslatorSuccess,
  updateWorkScheduleFailure,
  updateWorkScheduleRequest,
  updateWorkScheduleSuccess,
} from '../actions/translatorsActions';

interface TranslatorState {
  translators: Translator[];
  searchResults: Translator[];
  loading: boolean;
  error: string | null;
  pageIndex: number;
  orderDirection: string;
  fetchLimit: number;
}

const initialState: TranslatorState = {
  translators: [],
  searchResults: [],
  loading: false,
  error: null,
  pageIndex: 0,
  orderDirection: 'ASC',
  fetchLimit: 1,
};

const translatorsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadTranslatorsRequest, (state, action) => {
      state.loading = true;
      if (action.payload.pageIndex === 0) {
        state.translators = [];
        state.pageIndex = 0;
      }
      state.orderDirection = action.payload.orderDirection;
    })
    .addCase(loadTranslatorsSuccess, (state, action) => {
      state.loading = false;
      state.translators = action.payload;
      state.pageIndex = 1;
      state.searchResults = [];
    })
    .addCase(appendTranslatorsSuccess, (state, action) => {
      state.loading = false;
      state.translators = [...state.translators, ...action.payload];
      state.pageIndex += 1;
    })
    .addCase(loadTranslatorsFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(searchTranslatorsRequest, (state) => {
      state.loading = true;
    })
    .addCase(searchTranslatorsSuccess, (state, action) => {
      state.loading = false;
      state.searchResults = action.payload;
    })
    .addCase(searchTranslatorsFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(setFetchLimit, (state, action) => {
      state.fetchLimit = action.payload;
    })
    .addCase(deleteTranslator, (state, action) => {
      state.translators = state.translators.filter(
        (translator) => translator.id !== action.payload,
      );
      state.searchResults = state.searchResults.filter(
        (translator) => translator.id !== action.payload,
      );
    })
    .addCase(updateTranslatorSuccess, (state, action) => {
      const updatedTranslator = action.payload;
      const index = state.translators.findIndex(
        (t) => t.id === updatedTranslator.id,
      );
      if (index !== -1) {
        state.translators[index] = updatedTranslator;
      }
      const searchIndex = state.searchResults.findIndex(
        (t) => t.id === updatedTranslator.id,
      );
      if (searchIndex !== -1) {
        state.searchResults[searchIndex] = updatedTranslator;
      }
    })
    .addCase(updateWorkScheduleRequest, (state) => {
      state.loading = true;
    })
    .addCase(updateWorkScheduleSuccess, (state, action) => {
      state.loading = false;
      const updatedTranslator = action.payload;
      const index = state.translators.findIndex(
        (t) => t.id === updatedTranslator.id,
      );
      if (index !== -1) {
        state.translators[index] = updatedTranslator;
      }
      const searchIndex = state.searchResults.findIndex(
        (t) => t.id === updatedTranslator.id,
      );
      if (searchIndex !== -1) {
        state.searchResults[searchIndex] = updatedTranslator;
      }
    })
    .addCase(updateWorkScheduleFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});

export default translatorsReducer;
