import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { fetchClients } from '../../../store/thunks/clientsThunk';
import { Client } from '../../../types';
import ConfirmDeleteModal from '../../ConfirmationModal/ConfirmationModal';
import ClientDetailsModal from '../ClientDetailsModal/ClientDetailsModal';
import ModalWindow from '../ModalWindow/ModalWindow';
import style from './CustomerList.module.css';
import CustomerListHead from './CustomerListHead/CustomerListHead';
import CustomerListItem from './CustomerListItem/CustomerListItem';
import { deleteClient } from './helpers';

interface CustomerListProps {
  clients: Client[];
  sortingLogo: boolean;
  isRotated: boolean;
  toggleSortingDirection: () => void;
  handleSortArrowClick: () => void;
  observerTarget: React.RefObject<HTMLDivElement>;
}

const CustomerList: React.FC<CustomerListProps> = ({
  clients,
  sortingLogo,
  isRotated,
  toggleSortingDirection,
  handleSortArrowClick,
  observerTarget,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState<boolean>(false);
  const [buttonRef, setButtonRef] = useState<{
    current: HTMLButtonElement | null;
  } | null>(null);
  const clientRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleClientMenuClick = (client: Client, index: number) => {
    setSelectedClient(client);
    setButtonRef({ current: clientRefs.current[index] });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedClient(null);
  };

  const handleCloseDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };

  const handleDetails = () => {
    setIsModalOpen(false);
    setIsDetailsModalOpen(true);
  };

  const handleSendInvitation = () => {
    console.log('Надіслати запрошення:', selectedClient);
    handleCloseModal();
  };

  const handleDelete = async () => {
    if (selectedClient) {
      try {
        await deleteClient(selectedClient);
        await dispatch(fetchClients(0, 12, 'ASC'));
      } catch (error) {
        console.error('Помилка при видаленні клієнта:', error);
      } finally {
        setIsConfirmDeleteModalOpen(false);
        setSelectedClient(null);
      }
    }
  };

  const handleConfirmDelete = () => {
    setIsModalOpen(false);
    setIsConfirmDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setIsConfirmDeleteModalOpen(false);
    setSelectedClient(null);
  };

  const customerListClass =
    clients.length >= 9 ? style.customerList : style.customerListLimited;

  return (
    <div className={customerListClass}>
      <CustomerListHead
        sortingLogo={sortingLogo}
        isRotated={isRotated}
        toggleSortingDirection={toggleSortingDirection}
        handleSortArrowClick={handleSortArrowClick}
      />
      <div className={style.clientItems}>
        {clients.length === 0 ? (
          <div className={style.noClients}>
            Клієнтів не знайдено. Спробуйте інше ім’я або додайте нового
            клієнта, щоб розпочати роботу.
          </div>
        ) : (
          clients.map((client, index) => (
            <CustomerListItem
              key={`${client.id}-${index}`}
              client={client}
              index={index}
              clientRefs={clientRefs}
              handleClientMenuClick={handleClientMenuClick}
            />
          ))
        )}
        <div ref={observerTarget}></div>
      </div>
      {isModalOpen && selectedClient && buttonRef && (
        <ModalWindow
          client={selectedClient}
          onClose={handleCloseModal}
          onDetails={handleDetails}
          onSendInvitation={handleSendInvitation}
          onDelete={handleConfirmDelete}
          buttonRef={buttonRef}
        />
      )}
      {isDetailsModalOpen && selectedClient && (
        <ClientDetailsModal
          client={selectedClient}
          onClose={handleCloseDetailsModal}
        />
      )}
      {isConfirmDeleteModalOpen && (
        <ConfirmDeleteModal
          onConfirm={handleDelete}
          onCancel={handleCancelDelete}
          userName={selectedClient?.userName}
          avatarUrl={selectedClient?.avatarUrl}
          type={'Client'}
        />
      )}
    </div>
  );
};

export default CustomerList;
