import React from 'react';
import PushPanel from '../PushPanel/PushPanel';
import style from './plan.module.css';

const Plan: React.FC = () => {
  return (
    <div className={style.wrapPlan}>
      <PushPanel />
    </div>
  );
};

export default Plan;
