import { Route, Routes } from 'react-router-dom';
import './assets/styles/App.css';
import Administrator from './components/Administrator/Administrator';
import CancelTranslation from './components/CancelTranslation/CancelTranslation';
import Company from './components/Company/Company';
import Customer from './components/Customer/Customer';
import History from './components/Historys/Historys';
import MainLayout from './components/MainLayout/MainLayout';
import Plan from './components/Plan/Plan';
import ProtectedRoute from './components/PrivateRoute';
import PushMessage from './components/Push/Push';
import Reports from './components/Response/Response';
import Translators from './components/Translators/Translators';
import Settings from './components/preferences/Preferences';
import AuthPage from './pages/AuthPage/AuthPage';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthPage />} />
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="company" element={<Company />} />
        <Route path="customer" element={<Customer />} />
        <Route path="translators" element={<Translators />} />
        <Route path="administrator" element={<Administrator />} />
        <Route path="history" element={<History />} />
        <Route path="cancel_translation" element={<CancelTranslation />} />
        <Route path="reports" element={<Reports />} />
        <Route path="plan" element={<Plan />} />
        <Route path="push_message" element={<PushMessage />} />
        <Route path="settings" element={<Settings />} />
      </Route>
    </Routes>
  );
};

export default App;
