import React, { FC } from 'react';
import DeleteIcon from '../icons/deleteIcon';
import TrialingIcon from '../icons/trialingIcon';
import style from './Tag.module.css';

interface TagType {
  id: number;
  name: string;
}

interface TagProps {
  tag: TagType;
  isSelected: boolean;
  onTagClick: (tag: TagType) => void;
  onDeleteTag: (
    tagId: number,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
}

const Tag: FC<TagProps> = ({ tag, isSelected, onTagClick, onDeleteTag }) => (
  <div
    className={`${style.tag} ${isSelected ? style.selected : ''}`}
    onClick={() => onTagClick(tag)}
  >
    <button
      className={style.deleteButton}
      onClick={(event) => onDeleteTag(tag.id, event)}
    >
      <DeleteIcon />
    </button>
    {tag.name}
    {isSelected && (
      <span className={style.svgIcon}>
        <TrialingIcon />
      </span>
    )}
  </div>
);

export default Tag;
