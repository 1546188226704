import { AppDispatch } from '../../store/store';
import { fetchCompanies } from '../../store/thunks/companiesThunk';
import axiosInstance from '../../utils/axiosInstance';
import style from './Company.module.css';
import { Plan } from './planTypes';

export const loadCompanies = (
  dispatch: AppDispatch,
  pageIndex: React.MutableRefObject<number>,
  orderDirection: React.MutableRefObject<string>,
) => {
  dispatch(fetchCompanies(pageIndex.current, 18, orderDirection.current));
};

export const handleSortArrowClick = (
  setIsRotated: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setIsRotated((prev) => !prev);
};

export const getPlanClass = (plan: string) => {
  switch (plan) {
    case Plan.Consultation:
      return style.consultation;
    case Plan.EventTranslation:
      return style.eventTranslation;
    case Plan.Subscription:
      return style.subscription;
    case Plan.Extended:
      return style.extended;
    case Plan.Basic:
      return style.basic;
    default:
      return '';
  }
};

export const handleConfirmDelete = async (
  id: number,
  loadCompanies: () => void,
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  try {
    await axiosInstance.delete('/Admin/RemoveCompany', {
      params: { companyId: id },
    });
    loadCompanies();
  } catch (error) {
    console.error('Помилка при видаленні компанії:', error);
  }
  setShowConfirmModal(false);
};

export const resendCompanyPasswordAndQr = async (id: number) => {
  try {
    await axiosInstance.post('/Admin/ResendCompanyPasswordAndQr', {
      id: id.toString(),
    });
  } catch (error) {
    console.error('Error sending invitation:', error);
  }
};
