import { createReducer } from '@reduxjs/toolkit';
import { Company } from '../../types';
import {
  appendCompaniesSuccess,
  loadCompaniesFailure,
  loadCompaniesRequest,
  loadCompaniesSuccess,
  searchCompaniesFailure,
  searchCompaniesRequest,
  searchCompaniesSuccess,
} from '../actions/companyActions';

interface CompanyState {
  companies: Company[];
  searchResults: Company[];
  loading: boolean;
  error: string | null;
  pageIndex: number;
  orderDirection: string;
  hasMore: boolean;
}

const initialState: CompanyState = {
  companies: [],
  searchResults: [],
  loading: false,
  error: null,
  pageIndex: 0,
  orderDirection: 'ASC',
  hasMore: true,
};

const companiesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadCompaniesRequest, (state, action) => {
      state.loading = true;
      if (action.payload.pageIndex === 0) {
        state.companies = [];
        state.pageIndex = 0;
      }
      state.orderDirection = action.payload.orderDirection;
    })
    .addCase(loadCompaniesSuccess, (state, action) => {
      state.loading = false;
      state.companies = action.payload.items;
      state.pageIndex = 1;
      state.searchResults = [];
      state.hasMore = action.payload.hasMore;
    })
    .addCase(appendCompaniesSuccess, (state, action) => {
      state.loading = false;
      state.companies = [...state.companies, ...action.payload.items];
      state.pageIndex += 1;
      state.hasMore = action.payload.hasMore;
    })
    .addCase(loadCompaniesFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(searchCompaniesRequest, (state) => {
      state.loading = true;
    })
    .addCase(searchCompaniesSuccess, (state, action) => {
      state.loading = false;
      state.searchResults = action.payload;
    })
    .addCase(searchCompaniesFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});

export default companiesReducer;
