import axios from 'axios';
import { useEffect, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../store/reducers/snackbarSlice';
import { Company, Tag } from '../../../../types';
import axiosInstance from '../../../../utils/axiosInstance';

interface WorkTime {
  timeStart: string;
  timeEnd: string;
  dayOfTheWeek: number;
}

export const useCompanyForm = (companyData?: Company, isEdit = false) => {
  const dispatch = useDispatch();

  const initialCompanyState: Company = {
    id: 0,
    userName: '',
    name: '', // Додано поле name
    avatarUrl: '',
    plan: '',
    phone: '',
    email: '',
    workTime: [],
    edrpou: '',
    directorUserName: '',
    directorPosition: '',
    iban: '',
    website: '',
    numberDoc: '',
    timeDocumentFrom: '',
    timeDocumentTo: '',
    tags: [],
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
  } = useForm<Company>({
    defaultValues: companyData || initialCompanyState,
    mode: 'onChange',
  });

  const parseWorkTime = (workTime: WorkTime[]): WorkTime[] => {
    return workTime.map((wt) => ({
      ...wt,
      timeStart: wt.timeStart,
      timeEnd: wt.timeEnd,
      dayOfTheWeek: wt.dayOfTheWeek,
    }));
  };

  const [isDateModalOpen, setDateModalOpen] = useState(false);
  const [isWorkTimeModalOpen, setWorkTimeModalOpen] = useState(false);
  const [workTime, setWorkTime] = useState<WorkTime[]>(
    companyData && Array.isArray(companyData.workTime)
      ? parseWorkTime(companyData.workTime)
      : [],
  );
  const [selectedTags, setSelectedTags] = useState<Tag[]>(
    companyData?.tags || [],
  );

  useEffect(() => {
    if (companyData) {
      setWorkTime(companyData.workTime || []);
      setSelectedTags(companyData.tags || []);
      reset({ ...companyData });
    } else {
      reset(initialCompanyState);
    }
  }, [companyData, reset]);

  useEffect(() => {
    setValue('workTime', workTime);
    setValue('tags', selectedTags);
  }, [workTime, selectedTags, setValue]);

  const openDateModal = () => setDateModalOpen(true);
  const closeDateModal = () => setDateModalOpen(false);

  const openWorkTimeModal = () => setWorkTimeModalOpen(true);
  const closeWorkTimeModal = () => setWorkTimeModalOpen(false);

  const handleDateSelect = (dates: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    if (dates.startDate)
      setValue('timeDocumentFrom', dates.startDate.toISOString().split('T')[0]);
    if (dates.endDate)
      setValue('timeDocumentTo', dates.endDate.toISOString().split('T')[0]);
  };

  const handleWorkTimeSave = (workTime: WorkTime[]) => {
    setWorkTime(workTime);
  };

  const handleTagChange = (tags: Tag[]) => {
    setSelectedTags(tags);
  };

  const formatTimeWithSeconds = (time: string): string => {
    const [hours, minutes = '00', seconds = '00'] = time.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  };

  const transformDataToContract = (data: Company) => ({
    userData: {
      userName: data.userName,
      email: data.email,
      phone: data.phone,
      avatarUrl: data.avatarUrl || '',
      id: data.id.toString(),
    },
    workTime: data.workTime.map((time: WorkTime) => ({
      timeStart: formatTimeWithSeconds(time.timeStart),
      timeEnd: formatTimeWithSeconds(time.timeEnd),
      dayOfTheWeek: time.dayOfTheWeek,
    })),
    name: data.name, // Використовуємо нове поле name
    edrpou: data.edrpou,
    directorUserName: data.directorUserName,
    directorPosition: data.directorPosition,
    iban: data.iban,
    website: data.website,
    tagIds: data.tags.map((tag) => tag.id),
  });

  const onSubmit = async (data: Company) => {
    const transformedData = transformDataToContract(data);
    try {
      let responseData;
      if (isEdit) {
        responseData = await axiosInstance.patch(
          '/Admin/UpdateCompany',
          transformedData,
        );
      } else {
        responseData = await axiosInstance.put(
          '/Admin/AddCompany',
          transformedData,
        );
      }
      return responseData.data; // Повертаємо дані з відповіді сервера
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 400) {
          const errorMessage =
            error.response.data?.Message || error.response.data?.message;
          if (
            errorMessage &&
            errorMessage.toLowerCase().includes('user already exists')
          ) {
            dispatch(
              showSnackbar({
                message: 'Така компанія вже зареєстрована',
                type: 'error',
              }),
            );
          } else {
            console.log('Other 400 error:', errorMessage);
            dispatch(
              showSnackbar({
                message: 'Помилка запиту: Некоректні дані',
                type: 'error',
              }),
            );
          }
        } else {
          console.log('Other error:', error);
          dispatch(
            showSnackbar({
              message: 'Сталася помилка при збереженні компанії',
              type: 'error',
            }),
          );
        }
      } else {
        console.log('Unexpected error:', error);
        dispatch(
          showSnackbar({
            message: 'Сталася непередбачена помилка',
            type: 'error',
          }),
        );
      }
      throw error;
    }
  };

  return {
    control,
    handleSubmit,
    errors: errors as FieldErrors<Company>,
    isValid,
    reset,
    openDateModal,
    closeDateModal,
    isDateModalOpen,
    handleDateSelect,
    openWorkTimeModal,
    closeWorkTimeModal,
    isWorkTimeModalOpen,
    handleWorkTimeSave,
    onSubmit,
    watch,
    selectedTags,
    handleTagChange,
  };
};
