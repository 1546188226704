import { Client } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';
import style from './CustomerList.module.css';
import { Plan } from './planTypes';

export const getPlanClass = (plan: string) => {
  switch (plan) {
    case Plan.Consultation:
      return style.consultation;
    case Plan.EventTranslation:
      return style.eventTranslation;
    case Plan.Subscription:
      return style.subscription;
    case Plan.Extended:
      return style.extended;
    case Plan.Basic:
      return style.basic;
    default:
      return '';
  }
};

export const deleteClient = async (client: Client) => {
  try {
    await axiosInstance.delete(`/Admin/RemoveClient`, {
      params: { clientId: client.id },
    });
    console.log('Клієнт видалений:', client);
  } catch (error) {
    console.error('Помилка при видаленні клієнта:', error);
    throw error;
  }
};
