import React from 'react';

const Preferences: React.FC = () => {
  return (
    <div>
      <h2>Settings</h2>
    </div>
  );
};

export default Preferences;
