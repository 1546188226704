import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store/store';
import {
  fetchClients,
  searchClients,
} from '../../../store/thunks/clientsThunk';
import { Client } from '../../../types';

const useCustomerLogic = () => {
  const { jwtToken } = useSelector((state: RootState) => state.auth);
  const searchResults = useSelector(
    (state: RootState) => state.clients.searchResults,
  );
  const loading = useSelector((state: RootState) => state.clients.loading);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [sortingLogo, setSortingLogo] = useState<boolean>(false);
  const [isRotated, setIsRotated] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [allClients, setAllClients] = useState<Client[]>([]);
  const observerTarget = useRef<HTMLDivElement | null>(null);
  const initialLoad = useRef<boolean>(true);
  const loadClients = useCallback(
    (page: number, sortDirection: string) => {
      if (loading || isLoadingMore || isSearching) return;

      setIsLoadingMore(true);
      dispatch(fetchClients(page, 12, sortDirection)).then(
        (clients: Client[]) => {
          if (clients.length) {
            setAllClients((prevClients) => {
              const uniqueClients = clients.filter(
                (client) => !prevClients.some((c) => c.id === client.id),
              );
              return page === 0
                ? uniqueClients
                : [...prevClients, ...uniqueClients];
            });
            setHasNextPage(clients.length === 12);
          } else {
            setHasNextPage(false);
          }
          setIsLoadingMore(false);
        },
      );
    },
    [dispatch, loading, isLoadingMore, isSearching],
  );

  useEffect(() => {
    if (!jwtToken) {
      navigate('/');
    } else if (initialLoad.current) {
      initialLoad.current = false;
      loadClients(0, sortingLogo ? 'desc' : 'asc');
      setPageIndex(0);
    }
  }, [jwtToken, navigate, sortingLogo]);

  const loadMoreClients = useCallback(() => {
    if (hasNextPage && !loading && !isSearching && !isLoadingMore) {
      loadClients(pageIndex + 1, sortingLogo ? 'desc' : 'asc');
      setPageIndex((prevPage) => prevPage + 1);
    }
  }, [
    pageIndex,
    hasNextPage,
    loading,
    isSearching,
    isLoadingMore,
    sortingLogo,
    loadClients,
  ]);

  // Обсервер для скролінгу
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        !loading &&
        !isSearching &&
        !isLoadingMore &&
        allClients.length > 11
      ) {
        loadMoreClients();
      }
    }, options);

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [loadMoreClients, loading, isSearching, isLoadingMore, allClients.length]);

  const toggleSortingDirection = () => {
    setSortingLogo((prev) => !prev);
    setPageIndex(0);
  };

  const handleSortArrowClick = () => {
    setIsRotated((prev) => !prev);
  };

  const handleSearch = (query: string) => {
    setIsSearching(true);
    dispatch(searchClients(query)).then((results: Client[]) => {
      setIsSearching(false);
      setAllClients(results);
      setHasNextPage(results.length === 12);
      setPageIndex(0);
    });
  };

  return {
    sortingLogo,
    isRotated,
    allClients,
    observerTarget,
    searchResults,
    isSearching,
    handleSortArrowClick,
    toggleSortingDirection,
    handleSearch,
    setIsSearching,
  };
};

export default useCustomerLogic;
