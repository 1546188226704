import React from 'react';
import style from './button.module.css';

interface ButtonProps {
  content: any;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ content, onClick }) => {
  return (
    <div>
      <button onClick={onClick} className={style.button}>
        {content}
      </button>
    </div>
  );
};

export default Button;
