import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authSlice';
import clientsReducer from './reducers/clientsReducer';
import companiesReducer from './reducers/companiesReducer';
import scheduleReducer from './reducers/scheduleSlice';
import snackbarReducer from './reducers/snackbarSlice';
import translatorsReducer from './reducers/translatorsReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    schedule: scheduleReducer,
    translators: translatorsReducer,
    clients: clientsReducer,
    companies: companiesReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
