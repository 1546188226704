import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch } from '../../store/store';
import { searchClients } from '../../store/thunks/clientsThunk';
import { searchCompanies } from '../../store/thunks/companiesThunk';
import {
  fetchTranslators,
  searchTranslators,
} from '../../store/thunks/translatorsThunk';
import Snackbar from '../Snackbar/Snackbar';
import style from './ControlPanel.module.css';
import SearchInput from './SearchInput/SearchInput';
import TranslatorForm from './TranslatorForm/TranslatorForm';
import PlusLogo from './icons/Plus';

interface ControlPanelProps {
  setIsSearching: (isSearching: boolean) => void;
  onSearch: (query: string) => void;
  onAddCompany?: () => void;
  onAddAdmiration?: () => void;
}

const ControlPanel: React.FC<ControlPanelProps> = ({
  setIsSearching,
  onSearch,
  onAddCompany,
  onAddAdmiration,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    const handler = setTimeout(() => {
      if (query.length >= 2) {
        setIsSearching(true);
        if (location.pathname === '/customer') {
          dispatch(searchClients(query));
        } else if (location.pathname === '/translators') {
          dispatch(searchTranslators(query));
        } else if (location.pathname === '/company') {
          dispatch(searchCompanies(query));
        }
      } else if (query.length === 0) {
        setIsSearching(false);
        setQuery('');
      }
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [query, dispatch, setIsSearching, location.pathname]);

  const handleSearchChange = (query: string) => {
    setQuery(query);
    onSearch(query);
  };

  const handleClearSearch = () => {
    setQuery('');
    setIsSearching(false);
    onSearch('');
    if (location.pathname === '/translators') {
      dispatch(fetchTranslators(0, 20, 'ASC'));
    }
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  return (
    <div className={style.panel_wrap}>
      <SearchInput
        query={query}
        onChange={handleSearchChange}
        onClear={handleClearSearch}
      />
      <div className={style.container_button}>
        <button>Завантажити XML</button>
        {location.pathname === '/company' && onAddCompany && (
          <button onClick={onAddCompany}>
            <PlusLogo />
            Додати компанію
          </button>
        )}
        {location.pathname === '/translators' && (
          <button
            className={isHovered ? style.hovered : ''}
            onClick={toggleFormVisibility}
          >
            <PlusLogo />
            Додати перекладача
          </button>
        )}
        {location.pathname === '/administrator' && (
          <button onClick={onAddAdmiration}>
            <PlusLogo />
            Додати адміністратора
          </button>
        )}
      </div>
      {isFormVisible && <TranslatorForm onClose={toggleFormVisibility} />}
      <Snackbar />
    </div>
  );
};

export default ControlPanel;
